const data = [
  {
    name: "高强度功能-力量",
    videoId: "YDCF_070",
    videoUrl:
      "http://video.zhijianfit.com/sv/3e0b47d5-18165ef5a8d/3e0b47d5-18165ef5a8d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5F7AE01229CB41E685DE394BFCB7CEC5-6-2.jpg",
  },
  {
    name: "高强度功能-有氧",
    videoId: "YDCF_071",
    videoUrl:
      "http://video.zhijianfit.com/sv/f64525c-18165ef5aac/f64525c-18165ef5aac.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/082C54032FCE4081A8705A0AE34FDE0C-6-2.jpg",
  },
  {
    name: "高强度功能-平衡",
    videoId: "YDCF_072",
    videoUrl:
      "http://video.zhijianfit.com/sv/2292de34-18165ef5a98/2292de34-18165ef5a98.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D84CFBC4ACD84A199918B38E6FA8B9EF-6-2.jpg",
  },
];

export default data;
