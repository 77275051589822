const data = [
  {
    name: "001-滚轴股四头肌",
    videoId: "YDCF_070",
    videoUrl:
      "http://video.zhijianfit.com/sv/1ce01e6c-17e3e5166ce/1ce01e6c-17e3e5166ce.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/83AB5CDBB7B34FD9BF52619E44486912-6-2.jpg",
  },
  {
    name: "002-滚轴小腿三头肌",
    videoId: "YDCF_071",
    videoUrl:
      "http://video.zhijianfit.com/sv/448a28c1-17e3e51670c/448a28c1-17e3e51670c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/97165CEFCBE94F098F82BD3440D168DE-6-2.jpg",
  },
  {
    name: "003-多方向蝎式",
    videoId: "YDCF_072",
    videoUrl:
      "http://video.zhijianfit.com/sv/1ac39374-17e3e516859/1ac39374-17e3e516859.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5CD19FA6F02E4D0EBB0CC1B42D2B72E4-6-2.jpg",
  },
  {
    name: "004-滚轴臀大肌",
    videoId: "YDCF_073",
    videoUrl:
      "http://video.zhijianfit.com/sv/2096e3d8-17e3e5168b9/2096e3d8-17e3e5168b9.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C327323EA62847C48D72FDFFB6EFFDF1-6-2.jpg",
  },
  {
    name: "005-滚轴内收肌群",
    videoId: "YDCF_074",
    videoUrl:
      "http://video.zhijianfit.com/sv/13b6a1f9-17e3e516902/13b6a1f9-17e3e516902.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/330C1C92777D45158AECC0D5D7C3B626-6-2.jpg",
  },
  {
    name: "006-滚轴腘绳肌群",
    videoId: "YDCF_075",
    videoUrl:
      "http://video.zhijianfit.com/sv/20e961cc-17e3e516928/20e961cc-17e3e516928.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F9184D7F689D4A56AD4CF3B7E8D480A5-6-2.jpg",
  },
  {
    name: "007-蚌式",
    videoId: "YDCF_076",
    videoUrl:
      "http://video.zhijianfit.com/sv/1b4a3553-17e3e516961/1b4a3553-17e3e516961.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D82AD5B111004712B71CE859064F92D1-6-2.jpg",
  },
  {
    name: "008-侧桥内收肌训练",
    videoId: "YDCF_077",
    videoUrl:
      "http://video.zhijianfit.com/sv/18c115aa-17e3e516abb/18c115aa-17e3e516abb.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/899820BA79DA4DFDB9DDE68A531D6473-6-2.jpg",
  },
  {
    name: "009-花生球腰椎按压",
    videoId: "YDCF_065",
    videoUrl:
      "http://video.zhijianfit.com/sv/16eebf20-17e3e5163d9/16eebf20-17e3e5163d9.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CD39F8F2C08A4D2C9BF1640B929C23DA-6-2.jpg",
  },
  {
    name: "010-胶球臀中肌按压",
    videoId: "YDCF_066",
    videoUrl:
      "http://video.zhijianfit.com/sv/5fd59858-17e3e516409/5fd59858-17e3e516409.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C496C2175AA842898E318DB0E0FBB239-6-2.jpg",
  },
  {
    name: "011-胶球梨状肌按压",
    videoId: "YDCF_067",
    videoUrl:
      "http://video.zhijianfit.com/sv/55d570fd-17e3e51658c/55d570fd-17e3e51658c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/45ECC174297E4A75BF8C9367F55B5F03-6-2.jpg",
  },
  {
    name: "012-腹部举重",
    videoId: "YDCF_064",
    videoUrl:
      "http://video.zhijianfit.com/sv/79cfea1-17e3e5163a6/79cfea1-17e3e5163a6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/272B24CD14BD43B0B8AC7C6B472B6A0E-6-2.jpg",
  },
  {
    name: "013-死虫子",
    videoId: "YDCF_068",
    videoUrl:
      "http://video.zhijianfit.com/sv/45538645-17e3e516613/45538645-17e3e516613.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/69466E2B6F7E4C43AFBE81500C0EA9C1-6-2.jpg",
  },
  {
    name: "014-平板支撑",
    videoId: "YDCF_069",
    videoUrl:
      "http://video.zhijianfit.com/sv/60be63e9-17e3e51664d/60be63e9-17e3e51664d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/0A3F1740CED74941A59EB37A6554DF5B-6-2.jpg",
  },
  {
    name: "015-肩部I式",
    videoId: "YDCF_052",
    videoUrl:
      "http://video.zhijianfit.com/sv/25d8e149-17e3e515ecc/25d8e149-17e3e515ecc.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/2C51790C60FF4B93ACD4E0A7FB238CCD-6-2.jpg",
  },
  {
    name: "016-肩部O式",
    videoId: "YDCF_053",
    videoUrl:
      "http://video.zhijianfit.com/sv/4eee8e5d-17e3e515eec/4eee8e5d-17e3e515eec.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/152F7A1D40C34819A8E923DAC622C3EA-6-2.jpg",
  },
  {
    name: "017-肩部S式",
    videoId: "YDCF_054",
    videoUrl:
      "http://video.zhijianfit.com/sv/1c8442a1-17e3e515efd/1c8442a1-17e3e515efd.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/107D5AA1F5E14D0BACBB4E2CAEBF3D4E-6-2.jpg",
  },
  {
    name: "018-胸大肌牵拉",
    videoId: "YDCF_055",
    videoUrl:
      "http://video.zhijianfit.com/sv/1db944a4-17e512addef/1db944a4-17e512addef.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/49F66603136E484BBC39A93460AC3236-6-2.jpg",
  },
  {
    name: "019-背阔肌牵拉",
    videoId: "YDCF_056",
    videoUrl:
      "http://video.zhijianfit.com/sv/2da36317-17e512ade03/2da36317-17e512ade03.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CD4CB46C749A4C5F9936B1F7181B4444-6-2.jpg",
  },
  {
    name: "020-肱二头肌牵拉",
    videoId: "YDCF_057",
    videoUrl:
      "http://video.zhijianfit.com/sv/1c63b8cb-17e512ade08/1c63b8cb-17e512ade08.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7E88092E42C24D60ACF086378928424D-6-2.jpg",
  },
  {
    name: "021-肱三头肌牵拉",
    videoId: "YDCF_058",
    videoUrl:
      "http://video.zhijianfit.com/sv/56a502d8-17e3e516136/56a502d8-17e3e516136.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/82F7A1E4AEA54E5596FAF968FA396B3D-6-2.jpg",
  },
  {
    name: "022-肩袖PNF牵拉",
    videoId: "YDCF_061",
    videoUrl:
      "http://video.zhijianfit.com/sv/2f58c2e3-17e3e516217/2f58c2e3-17e3e516217.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/EFFD20EE461D4AC3AFE0658E9EB2E35B-6-2.jpg",
  },
  {
    name: "023-蹲姿提踵",
    videoId: "YDCF_096",
    videoUrl:
      "http://video.zhijianfit.com/sv/3a864fe4-17e3e517171/3a864fe4-17e3e517171.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D644C163431A42B58C361C052585CD4C-6-2.jpg",
  },
  {
    name: "024-后撤步斜向转体",
    videoId: "YDCF_083",
    videoUrl:
      "http://video.zhijianfit.com/sv/4ac96da7-17e3e516ce2/4ac96da7-17e3e516ce2.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/32A1AAB173DB4A81B7855821F328BF58-6-2.jpg",
  },
  {
    name: "025-胶球冈下肌内外旋",
    videoId: "YDCF_059",
    videoUrl:
      "http://video.zhijianfit.com/sv/2dfd32c5-17e3e516171/2dfd32c5-17e3e516171.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E2B373EE7BC34984B288E42743770B6B-6-2.jpg",
  },
  {
    name: "026-胶球小圆肌内外旋",
    videoId: "YDCF_060",
    videoUrl:
      "http://video.zhijianfit.com/sv/56dd194b-17e3e5161a0/56dd194b-17e3e5161a0.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/B5B9C7ADEA144F46A8B3663AD7C66653-6-2.jpg",
  },
  {
    name: "027-箱式转体",
    videoId: "YDCF_063",
    videoUrl:
      "http://video.zhijianfit.com/sv/20761af6-17e3e51637a/20761af6-17e3e51637a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F1B3AB12675B4C329F9678319ACBC32A-6-2.jpg",
  },
  {
    name: "028-绕肩A/B/C",
    videoId: "YDCF_078",
    videoUrl:
      "http://video.zhijianfit.com/sv/4cad052d-17e512ade19/4cad052d-17e512ade19.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7BC5F1F49E1A4335A29A05D66F6AE133-6-2.jpg",
  },
  {
    name: "029-前后熊爬",
    videoId: "YDCF_097",
    videoUrl:
      "http://video.zhijianfit.com/sv/2d20be23-17e3e51726f/2d20be23-17e3e51726f.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7D13C0DCC0E04EC8B49ADF99A595FCE9-6-2.jpg",
  },
  {
    name: "030-招财猫",
    videoId: "YDCF_081",
    videoUrl:
      "http://video.zhijianfit.com/sv/4de99ea7-17e3e516b8b/4de99ea7-17e3e516b8b.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C22FB024C66B4AAA921EBC4BD1B27C47-6-2.jpg",
  },
  {
    name: "031-徒手大风车",
    videoId: "YDCF_082",
    videoUrl:
      "http://video.zhijianfit.com/sv/3c1f362-17e3e516ca0/3c1f362-17e3e516ca0.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E38BB301309C452E8CC0DB0A7AADF9A4-6-2.jpg",
  },
  {
    name: "032-翻书",
    videoId: "YDCF_062",
    videoUrl:
      "http://video.zhijianfit.com/sv/40b3fb41-17e3e51631d/40b3fb41-17e3e51631d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C38D6E2BF79E4C55AAFEBDAE66DBB252-6-2.jpg",
  },
  {
    name: "033-剪蹲转体",
    videoId: "YDCF_084",
    videoUrl:
      "http://video.zhijianfit.com/sv/20d88c32-17e3e516cf4/20d88c32-17e3e516cf4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7764AE5238394335A23BEB8A02A5002A-6-2.jpg",
  },
  {
    name: "034-猫式",
    videoId: "YDCF_085",
    videoUrl:
      "http://video.zhijianfit.com/sv/458a7942-17e3e516d3a/458a7942-17e3e516d3a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/2F5B6B0106D040F3AD434E58F2F4B03B-6-2.jpg",
  },
  {
    name: "035-大跨步后仰",
    videoId: "YDCF_086",
    videoUrl:
      "http://video.zhijianfit.com/sv/2456c32a-17e3e516d6d/2456c32a-17e3e516d6d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CF903ECED3284108B4CAB9FB07798E39-6-2.jpg",
  },
  {
    name: "036-猴蹲",
    videoId: "YDCF_087",
    videoUrl:
      "http://video.zhijianfit.com/sv/ea1ab5e-17e3e516e68/ea1ab5e-17e3e516e68.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/39E81D1B12A34F61BADDD2AB431AE32A-6-2.jpg",
  },
  {
    name: "037-蝎式伸展",
    videoId: "YDCF_088",
    videoUrl:
      "http://video.zhijianfit.com/sv/609b8a06-17e3e516e8e/609b8a06-17e3e516e8e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/017831BBEBBC4953887793DDE934C4C4-6-2.jpg",
  },
  {
    name: "038-直腿爬行",
    videoId: "YDCF_089",
    videoUrl:
      "http://video.zhijianfit.com/sv/5109e403-17e3e516ee3/5109e403-17e3e516ee3.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3DD92D920B724192B702C8EA359DAB1B-6-2.jpg",
  },
  {
    name: "039-交替A字支撑",
    videoId: "YDCF_090",
    videoUrl:
      "http://video.zhijianfit.com/sv/15573ae4-17e3e516f1b/15573ae4-17e3e516f1b.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/349C7A3E0E0A4C91A74CA5706DAC3492-6-2.jpg",
  },
  {
    name: "040-侧桥转体",
    videoId: "YDCF_091",
    videoUrl:
      "http://video.zhijianfit.com/sv/6153ac62-17e3e516f57/6153ac62-17e3e516f57.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E6ED5D2AB4134386836138CAB5FB44ED-6-2.jpg",
  },
  {
    name: "041-侧步提膝稳定",
    videoId: "YDCF_092",
    videoUrl:
      "http://video.zhijianfit.com/sv/2b0f9f8e-17e3e517069/2b0f9f8e-17e3e517069.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/731B65B0FB204E01901E86F563807EA4-6-2.jpg",
  },
  {
    name: "042-十字支撑",
    videoId: "YDCF_093",
    videoUrl:
      "http://video.zhijianfit.com/sv/533637cc-17e3e5170a9/533637cc-17e3e5170a9.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/75D9B2D5189D4D8CAA59095CB09B48B6-6-2.jpg",
  },
  {
    name: "043-剪蹲交替垂直跳",
    videoId: "YDCF_094",
    videoUrl:
      "http://video.zhijianfit.com/sv/18deed0a-17e3e51712c/18deed0a-17e3e51712c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E804C8195F054B7E80677D4131C1204E-6-2.jpg",
  },
  {
    name: "044-脚十字",
    videoId: "YDCF_095",
    videoUrl:
      "http://video.zhijianfit.com/sv/50eadee-17e3e51714e/50eadee-17e3e51714e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/4F58281C69264D7C93009B3617826D46-6-2.jpg",
  },
  {
    name: "045-站姿臂弯举",
    videoId: "YDFH_001",
    videoUrl:
      "http://video.zhijianfit.com/sv/61bd9646-193613360e7/61bd9646-193613360e7.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CCDF833E41774E0F880B4ABE387BD5A9-6-2.jpg",
  },
  {
    name: "046-站姿交替臂弯举",
    videoId: "YDFH_002",
    videoUrl:
      "http://video.zhijianfit.com/sv/3f646d25-193613360f3/3f646d25-193613360f3.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/8D795D63A3DE4454889B41DEED7A7070-6-2.jpg",
  },
  {
    name: "047-站姿单侧臂弯举",
    videoId: "YDFH_003",
    videoUrl:
      "http://video.zhijianfit.com/sv/63df515f-193613360fa/63df515f-193613360fa.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/88285773E7364C33A836018AEAEFE2C2-6-2.jpg",
  },
  {
    name: "048-站姿锤式臂弯举",
    videoId: "YDFH_005",
    videoUrl:
      "http://video.zhijianfit.com/sv/51d77795-193613360d1/51d77795-193613360d1.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/0862B44103E649D58011574A1DD89CE8-6-2.jpg",
  },
  {
    name: "049-站姿锤式交替臂弯举",
    videoId: "YDFH_006",
    videoUrl:
      "http://video.zhijianfit.com/sv/3f045553-193613360cd/3f045553-193613360cd.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5A0CE615ADFF49D5B43D5AE7E15981FA-6-2.jpg",
  },
  {
    name: "050-站姿杠铃臂弯举",
    videoId: "YDFH_007",
    videoUrl:
      "http://video.zhijianfit.com/sv/58be2298-1936133680d/58be2298-1936133680d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/820DEA58CF7E4C82B2D36D719FB3B136-6-2.jpg",
  },
  {
    name: "051-坐姿上斜交替锤式臂弯举",
    videoId: "YDFH_010",
    videoUrl:
      "http://video.zhijianfit.com/sv/152439e5-19361336816/152439e5-19361336816.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/4F67C30F148445E584E63BCDD3218163-6-2.jpg",
  },
  {
    name: "052-坐姿上斜锤式臂弯举",
    videoId: "YDFH_011",
    videoUrl:
      "http://video.zhijianfit.com/sv/6205df79-1936133680c/6205df79-1936133680c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/663FF3813F0C46E6A9E607B1CD62BA37-6-2.jpg",
  },
  {
    name: "053-牧师臂弯举",
    videoId: "YDFH_016",
    videoUrl:
      "http://video.zhijianfit.com/sv/204f2ad1-19361336828/204f2ad1-19361336828.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/AEB562C32D934F7F9EE30F0878DBAB49-6-2.jpg",
  },
  {
    name: "054-站姿三头绳锤式臂弯举",
    videoId: "YDFH_018",
    videoUrl:
      "http://video.zhijianfit.com/sv/1771f3ff-19361336809/1771f3ff-19361336809.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D6B0E5B8B6D74229AAA570115CA3D13E-6-2.jpg",
  },
  {
    name: "055-俯身臂屈伸",
    videoId: "YDFH_024",
    videoUrl:
      "http://video.zhijianfit.com/sv/3cad989b-19361336b94/3cad989b-19361336b94.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CFFBFBC16EAC4D458839C591C3A6A727-6-2.jpg",
  },
  {
    name: "056-仰卧上斜三头绳臂屈伸",
    videoId: "YDFH_025",
    videoUrl:
      "http://video.zhijianfit.com/sv/1bbcd420-19361336c01/1bbcd420-19361336c01.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9E9B9FA5F6E848E684C39AB71D466215-6-2.jpg",
  },
  {
    name: "057-站姿过顶臂屈伸",
    videoId: "YDFH_026",
    videoUrl:
      "http://video.zhijianfit.com/sv/433e6418-19361336ca4/433e6418-19361336ca4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9FA54851D17C48228E848295729CA789-6-2.jpg",
  },
  {
    name: "058-站姿臂屈伸",
    videoId: "YDFH_027",
    videoUrl:
      "http://video.zhijianfit.com/sv/3d76c11b-19361336cc4/3d76c11b-19361336cc4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3208EC9B7021465E8F6F333FB196F8B9-6-2.jpg",
  },
  {
    name: "059-俯身支撑式臂屈伸",
    videoId: "YDFH_028",
    videoUrl:
      "http://video.zhijianfit.com/sv/4ea3741-19361336ef5/4ea3741-19361336ef5.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/A0A857C7FEDB4BF29F09858FD8CFAFEF-6-2.jpg",
  },
  {
    name: "060-坐姿对握高位下拉",
    videoId: "YDFH_030",
    videoUrl:
      "http://video.zhijianfit.com/sv/5af621f1-19361336f59/5af621f1-19361336f59.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C29C06DCABE64BD7AFD4787A9C11388D-6-2.jpg",
  },
  {
    name: "061-坐姿交替高位下拉",
    videoId: "YDFH_031",
    videoUrl:
      "http://video.zhijianfit.com/sv/5d2b030c-19361337165/5d2b030c-19361337165.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/749472E50D1B4DE5B5CFD02B6FDF1FFD-6-2.jpg",
  },
  {
    name: "062-坐姿单手高位下拉",
    videoId: "YDFH_032",
    videoUrl:
      "http://video.zhijianfit.com/sv/3eaa44ca-193613371ca/3eaa44ca-193613371ca.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/67BEC2243FDB41CA8549C39FC0CAF3AA-6-2.jpg",
  },
  {
    name: "063-坐姿反握杠铃下拉",
    videoId: "YDFH_035",
    videoUrl:
      "http://video.zhijianfit.com/sv/231e7d43-19361337410/231e7d43-19361337410.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/17D801C416364BF1BE22FB078118E45D-6-2.jpg",
  },
  {
    name: "064-X下拉",
    videoId: "YDFH_036",
    videoUrl:
      "http://video.zhijianfit.com/sv/2e4639e1-19361337477/2e4639e1-19361337477.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F087517A44874B249DEA51622E7C409F-6-2.jpg",
  },
  {
    name: "065-跪姿交替高位下拉",
    videoId: "YDFH_038",
    videoUrl:
      "http://video.zhijianfit.com/sv/49982984-1936133757c/49982984-1936133757c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/0DA0122E3D4345079290674E45E5203F-6-2.jpg",
  },
  {
    name: "066-跪姿把手高位下拉",
    videoId: "YDFH_039",
    videoUrl:
      "http://video.zhijianfit.com/sv/109ce31d-19361337648/109ce31d-19361337648.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/54BDF225FD494AFFB4BB63AE24CC14E1-6-2.jpg",
  },
  {
    name: "067-俯身杠铃划船",
    videoId: "YDFH_044",
    videoUrl:
      "http://video.zhijianfit.com/sv/1b73050-193613376f7/1b73050-193613376f7.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/61069831D35244069536D76DC18ED5AC-6-2.jpg",
  },
  {
    name: "068-耶茨划船",
    videoId: "YDFH_045",
    videoUrl:
      "http://video.zhijianfit.com/sv/3e163d1-19361337834/3e163d1-19361337834.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7C925B35AC514773B2113D50BA45A855-6-2.jpg",
  },
  {
    name: "069-俯身交替划船",
    videoId: "YDFH_046",
    videoUrl:
      "http://video.zhijianfit.com/sv/6912813-19361337a4a/6912813-19361337a4a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/320BAB203D92495985AD2E5CB4C42341-6-2.jpg",
  },
  {
    name: "070-坐姿双把手划船",
    videoId: "YDFH_047",
    videoUrl:
      "http://video.zhijianfit.com/sv/5aac2ecf-19361337a84/5aac2ecf-19361337a84.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/0663A29E75F04278A12AA7B984C4F7B9-6-2.jpg",
  },
  {
    name: "071-坐姿面拉",
    videoId: "YDFH_049",
    videoUrl:
      "http://video.zhijianfit.com/sv/33c0b08-19361337b9b/33c0b08-19361337b9b.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3839D3AD33E4426CBC4C6CDA48070D61-6-2.jpg",
  },
  {
    name: "072-俯身支撑单侧划船",
    videoId: "YDFH_050",
    videoUrl:
      "http://video.zhijianfit.com/sv/5ac51ec-19361337c23/5ac51ec-19361337c23.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/BBD63B139EBD41598ED83EE64F0E9138-6-2.jpg",
  },
  {
    name: "073-半跪双把手划船",
    videoId: "YDFH_052",
    videoUrl:
      "http://video.zhijianfit.com/sv/44bc2156-19361337cf6/44bc2156-19361337cf6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/BF365CF590C94F54A29EC2C527342D3E-6-2.jpg",
  },
  {
    name: "074-半跪双把手交替划船",
    videoId: "YDFH_054",
    videoUrl:
      "http://video.zhijianfit.com/sv/af0fdcf-19361337eec/af0fdcf-19361337eec.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/BE9070C1D60B410585723DE190A53F6A-6-2.jpg",
  },
  {
    name: "075-杠铃窄距卧推",
    videoId: "YDFH_059",
    videoUrl:
      "http://video.zhijianfit.com/sv/1f31c7d2-19361338004/1f31c7d2-19361338004.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/429E924B606340CC9DB8E161D08A8BC0-6-2.jpg",
  },
  {
    name: "076-双把手卧推",
    videoId: "YDFH_060",
    videoUrl:
      "http://video.zhijianfit.com/sv/110a5fc7-19361338086/110a5fc7-19361338086.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3D0CA68A280D46879C2EA3C3424630C0-6-2.jpg",
  },
  {
    name: "077-双把手交替卧推",
    videoId: "YDFH_061",
    videoUrl:
      "http://video.zhijianfit.com/sv/48efdb23-19361338167/48efdb23-19361338167.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CE3A2733B6894AE69847F9E975021708-6-2.jpg",
  },
  {
    name: "078-单侧握推",
    videoId: "YDFH_062",
    videoUrl:
      "http://video.zhijianfit.com/sv/4d97d52f-193613381c4/4d97d52f-193613381c4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/EB0B51FB51A547239D35C7B1D578EF22-6-2.jpg",
  },
  {
    name: "079-上斜双把手卧推",
    videoId: "YDFH_064",
    videoUrl:
      "http://video.zhijianfit.com/sv/55cb9a35-193613382fa/55cb9a35-193613382fa.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/978AAD97C85C4A1F9A43E4CA417B7F73-6-2.jpg",
  },
  {
    name: "080-坐姿胸前推",
    videoId: "YDFH_065",
    videoUrl:
      "http://video.zhijianfit.com/sv/55262134-19361338439/55262134-19361338439.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/76EC6AA3CAA14ED3B272181A9A7FF3BA-6-2.jpg",
  },
  {
    name: "081-坐姿下斜胸前推",
    videoId: "YDFH_066",
    videoUrl:
      "http://video.zhijianfit.com/sv/4f1fc452-193613384b0/4f1fc452-193613384b0.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F04C9FC8CA41471D851A6ADF03707F1D-6-2.jpg",
  },
  {
    name: "082-坐姿把手过顶推",
    videoId: "YDFH_069",
    videoUrl:
      "http://video.zhijianfit.com/sv/476231e2-193613385e6/476231e2-193613385e6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/95F06395CC4449F699D2A107E1E64EC7-6-2.jpg",
  },
  {
    name: "083-坐姿交替过顶推",
    videoId: "YDFH_071",
    videoUrl:
      "http://video.zhijianfit.com/sv/62291a27-19361334834/62291a27-19361334834.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/20A02B44137D4B75B8F79782D0D0D47C-6-2.jpg",
  },
  {
    name: "084-站姿过顶推",
    videoId: "YDFH_072",
    videoUrl:
      "http://video.zhijianfit.com/sv/164d69f5-19361338711/164d69f5-19361338711.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F20C05BB140D4B728FE5A5CA617FFD64-6-2.jpg",
  },
  {
    name: "085-站姿交替过顶推",
    videoId: "YDFH_073",
    videoUrl:
      "http://video.zhijianfit.com/sv/5a35779c-1936133893e/5a35779c-1936133893e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/68988644EB1C4C139DA05DF4E6984D7E-6-2.jpg",
  },
  {
    name: "086-站姿单侧过顶推",
    videoId: "YDFH_074",
    videoUrl:
      "http://video.zhijianfit.com/sv/5cb0f126-19361338981/5cb0f126-19361338981.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/04F0F979E734484EB51CE5FFC8209AEA-6-2.jpg",
  },
  {
    name: "087-跪姿过顶推",
    videoId: "YDFH_079",
    videoUrl:
      "http://video.zhijianfit.com/sv/50cb24e1-19361338ad7/50cb24e1-19361338ad7.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3A65227846C64E05AB963C986A5C9BCC-6-2.jpg",
  },
  {
    name: "088-对握深蹲",
    videoId: "YDFH_084",
    videoUrl:
      "http://video.zhijianfit.com/sv/357a9143-19361338b2e/357a9143-19361338b2e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F53282D0C96240CEBA19D628458753DE-6-2.jpg",
  },
  {
    name: "089-前握深蹲",
    videoId: "YDFH_087",
    videoUrl:
      "http://video.zhijianfit.com/sv/28d3f34c-19361338ccf/28d3f34c-19361338ccf.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9620B96900DC433AAA09EABF7C354CB1-6-2.jpg",
  },
  {
    name: "090-深蹲划船",
    videoId: "YDFH_088",
    videoUrl:
      "http://video.zhijianfit.com/sv/23d0486a-19361338e0c/23d0486a-19361338e0c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F08F7D700FFF4B858ED931E3C74A2CD8-6-2.jpg",
  },
  {
    name: "091-高脚杯剪蹲",
    videoId: "YDFH_093",
    videoUrl:
      "http://video.zhijianfit.com/sv/31ed5962-19361338e2a/31ed5962-19361338e2a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5692E23F8341484D8D883418FAE83D30-6-2.jpg",
  },
  {
    name: "092-高脚杯后撤蹲",
    videoId: "YDFH_095",
    videoUrl:
      "http://video.zhijianfit.com/sv/54f19d24-19361338ee1/54f19d24-19361338ee1.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/08AFF85451984AB08E13AC7D1B4708B0-6-2.jpg",
  },
  {
    name: "093-高脚杯屈膝礼弓步交替蹲",
    videoId: "YDFH_097",
    videoUrl:
      "http://video.zhijianfit.com/sv/15c4c4a2-19361338fc3/15c4c4a2-19361338fc3.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/B9DC10CC57E646F3B5F8E8A78B859901-6-2.jpg",
  },
  {
    name: "094-双把手剪蹲",
    videoId: "YDFH_108",
    videoUrl:
      "http://video.zhijianfit.com/sv/57fdd79d-193613391a5/57fdd79d-193613391a5.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9BF9394472CA487994E866276C5DD3AD-6-2.jpg",
  },
  {
    name: "095-对握硬拉",
    videoId: "YDFH_112",
    videoUrl:
      "http://video.zhijianfit.com/sv/3e0f3f2b-193613354b0/3e0f3f2b-193613354b0.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/16EBDB512CAA4722A2935752BDDE5A14-6-2.jpg",
  },
  {
    name: "096-杠铃相扑硬拉",
    videoId: "YDFH_113",
    videoUrl:
      "http://video.zhijianfit.com/sv/5156fac5-1936133930b/5156fac5-1936133930b.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/6CF642C1251A457CBB2B6969963F4A03-6-2.jpg",
  },
  {
    name: "097-仰卧直臂抗阻卷腹+",
    videoId: "YDFH_117",
    videoUrl:
      "http://video.zhijianfit.com/sv/b8acbd9-19361339428/b8acbd9-19361339428.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/A21E8FC917444BBF8984CCF90F3C6F8A-6-2.jpg",
  },
  {
    name: "098-仰卧直臂抗阻卷腹",
    videoId: "YDFH_118",
    videoUrl:
      "http://video.zhijianfit.com/sv/32ad5664-1936133954a/32ad5664-1936133954a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/EE9005A6F7954AD29B008C847342A083-6-2.jpg",
  },
  {
    name: "099-跪姿平板撑划船",
    videoId: "YDFH_134",
    videoUrl:
      "http://video.zhijianfit.com/sv/88eadac-19361339585/88eadac-19361339585.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C77871E1738648F9A90EAC345A806C6F-6-2.jpg",
  },
  {
    name: "100-站姿提拉",
    videoId: "YDFH_148",
    videoUrl:
      "http://video.zhijianfit.com/sv/568698b5-19361339852/568698b5-19361339852.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5AF04743BECD4289B5659576A0F9F4B7-6-2.jpg",
  },
  {
    name: "101-站姿劈砍",
    videoId: "YDFH_158",
    videoUrl:
      "http://video.zhijianfit.com/sv/2f798fab-19361339856/2f798fab-19361339856.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/AB8070771DEE4857A63E1CFEA5AE084D-6-2.jpg",
  },
  {
    name: "102-半跪劈砍-左",
    videoId: "YDFH_160",
    videoUrl:
      "http://video.zhijianfit.com/sv/fabf391-19361339901/fabf391-19361339901.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/AF3CA6C4A036407898EE2FD22BBAF326-6-2.jpg",
  },
  {
    name: "103-半跪+劈砍",
    videoId: "YDFH_162",
    videoUrl:
      "http://video.zhijianfit.com/sv/4489eeaa-1936133994b/4489eeaa-1936133994b.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/120D8D597FBC46AAA4E7D0B99CF6C7FB-6-2.jpg",
  },
  {
    name: "104-火箭推",
    videoId: "YDFH_172",
    videoUrl:
      "http://video.zhijianfit.com/sv/5c2d869f-19361339a68/5c2d869f-19361339a68.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CBF448FD0F244203A70A69F0AFB6CF49-6-2.jpg",
  },
  {
    name: "105-剪蹲火箭推",
    videoId: "YDFH_173",
    videoUrl:
      "http://video.zhijianfit.com/sv/bcc2fea-19361339cdd/bcc2fea-19361339cdd.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/566E50C253624292939E8781BD608CA1-6-2.jpg",
  },
  {
    name: "106-偏载火箭推",
    videoId: "YDFH_175",
    videoUrl:
      "http://video.zhijianfit.com/sv/35b65a67-19361339d6a/35b65a67-19361339d6a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F15EB9A634FC493F88CE873659566710-6-2.jpg",
  },
  {
    name: "107-前平举",
    videoId: "YDFH_205",
    videoUrl:
      "http://video.zhijianfit.com/sv/675d7f7-19361339dd5/675d7f7-19361339dd5.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D6C30CBFF94F46D6A6ED4B57B4D4BC26-6-2.jpg",
  },
  {
    name: "108-站姿直臂下压",
    videoId: "YDFH_207",
    videoUrl:
      "http://video.zhijianfit.com/sv/4bd8fddc-19361339de7/4bd8fddc-19361339de7.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F08C316E2A8648D2BE9B8A87CDB7944A-6-2.jpg",
  },
  {
    name: "109-仰卧直臂屈伸",
    videoId: "YDFH_208",
    videoUrl:
      "http://video.zhijianfit.com/sv/280f48a5-19361339fcc/280f48a5-19361339fcc.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3A14042C01F34CE981909D451BE78628-6-2.jpg",
  },
  {
    name: "110-俯桥",
    videoId: "YDFH_212",
    videoUrl:
      "http://video.zhijianfit.com/sv/4c859624-1936133a127/4c859624-1936133a127.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9F6885031CE6428EBB08AE9C2D6F33DF-6-2.jpg",
  },
  {
    name: "111-背桥",
    videoId: "YDFH_213",
    videoUrl:
      "http://video.zhijianfit.com/sv/d07de23-1936133a299/d07de23-1936133a299.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/A6FE653C96104EB0B7EA12AF08AF3FAF-6-2.jpg",
  },
  {
    name: "112-俯卧撑",
    videoId: "YDFH_214",
    videoUrl:
      "http://video.zhijianfit.com/sv/498a81bd-1936133a34e/498a81bd-1936133a34e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/13D686AA9CC647F29492CDBAAB8A6297-6-2.jpg",
  },
  {
    name: "113-传统硬拉",
    videoId: "YDFH_215",
    videoUrl:
      "http://video.zhijianfit.com/sv/6725894-1936133a390/6725894-1936133a390.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/67B5215B72514CCA87D92E047A16FBB7-6-2.jpg",
  },
  {
    name: "114-背蹲",
    videoId: "YDFH_300",
    videoUrl:
      "http://video.zhijianfit.com/sv/6e945b4-1936133a492/6e945b4-1936133a492.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/51587FC3D2934F848B8CF46EA372B0B5-6-2.jpg",
  },
  {
    name: "115-前蹲",
    videoId: "YDFH_301",
    videoUrl:
      "http://video.zhijianfit.com/sv/614b6f15-1936133a54c/614b6f15-1936133a54c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7DFB9DCCCCEA42BFB9ECFA03B8D345E7-6-2.jpg",
  },
  {
    name: "116-箱蹲",
    videoId: "YDFH_302",
    videoUrl:
      "http://video.zhijianfit.com/sv/2df33063-1936133a69d/2df33063-1936133a69d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/39E68D47B56244E0B0E392611774CC2A-6-2.jpg",
  },
  {
    name: "117-单腿蹲",
    videoId: "YDFH_308",
    videoUrl:
      "http://video.zhijianfit.com/sv/40076f3e-1936133a71c/40076f3e-1936133a71c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/EB161E67925A4A128B3013B3D9AF31F8-6-2.jpg",
  },
  {
    name: "118-单腿箱蹲",
    videoId: "YDFH_311",
    videoUrl:
      "http://video.zhijianfit.com/sv/3f80d276-1936133a8ef/3f80d276-1936133a8ef.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/BBE66BA7521B4B5EB60632BE3210DA11-6-2.jpg",
  },
  {
    name: "119-早安式屈髋",
    videoId: "YDFH_316",
    videoUrl:
      "http://video.zhijianfit.com/sv/2846684a-1936133a906/2846684a-1936133a906.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/36CE0E9BCCD54FE6B7FF1FE10C654D82-6-2.jpg",
  },
  {
    name: "120-臀冲",
    videoId: "YDFH_317",
    videoUrl:
      "http://video.zhijianfit.com/sv/35c1ab36-1936133aad4/35c1ab36-1936133aad4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/27EBC9A54AA14EF0ABD7CD2D8C3411DB-6-2.jpg",
  },
  {
    name: "121-臀拉",
    videoId: "YDFH_318",
    videoUrl:
      "http://video.zhijianfit.com/sv/58af99e4-1936133ab7f/58af99e4-1936133ab7f.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/001A45E9DCE344AC9893B2BBC6AB4796-6-2.jpg",
  },
  {
    name: "122-臀桥",
    videoId: "YDFH_319",
    videoUrl:
      "http://video.zhijianfit.com/sv/16e1368b-1936133ac1d/16e1368b-1936133ac1d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/83D93E72C26749218060581D5208C1D6-6-2.jpg",
  },
  {
    name: "123-单腿早安式屈髋",
    videoId: "YDFH_325",
    videoUrl:
      "http://video.zhijianfit.com/sv/4fdeb448-1936133ad80/4fdeb448-1936133ad80.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/EF080AEB4A8E43D98930F50730AB6203-6-2.jpg",
  },
  {
    name: "124-单腿臀冲",
    videoId: "YDFH_326",
    videoUrl:
      "http://video.zhijianfit.com/sv/5168c0cc-1936133adda/5168c0cc-1936133adda.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/DD944112DC7E40C698C119854957BCFA-6-2.jpg",
  },
  {
    name: "125-单腿臀桥",
    videoId: "YDFH_327",
    videoUrl:
      "http://video.zhijianfit.com/sv/3e9850fa-1936133af91/3e9850fa-1936133af91.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/6EC43264CEB64B0FA8FEC5C3D363D6B8-6-2.jpg",
  },
  {
    name: "126-前弓箭步",
    videoId: "YDFH_333",
    videoUrl:
      "http://video.zhijianfit.com/sv/270ff784-193613371f3/270ff784-193613371f3.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/58F65B6153014E1B97C9474286D51D49-6-2.jpg",
  },
  {
    name: "127-等长前弓箭步",
    videoId: "YDFH_335",
    videoUrl:
      "http://video.zhijianfit.com/sv/29628cfb-1936133b074/29628cfb-1936133b074.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9068B28695AC4968B0042CE4EBA7CAB4-6-2.jpg",
  },
  {
    name: "128-后弓箭步",
    videoId: "YDFH_341",
    videoUrl:
      "http://video.zhijianfit.com/sv/462ff70f-1936133b23e/462ff70f-1936133b23e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/8FABEC885D754948ACDDE06FDCF892CF-6-2.jpg",
  },
  {
    name: "129-侧弓箭步",
    videoId: "YDFH_347",
    videoUrl:
      "http://video.zhijianfit.com/sv/1ca9edee-1936133b2fe/1ca9edee-1936133b2fe.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/346E76BF30D34A3A999B5AA197F4BF05-6-2.jpg",
  },
  {
    name: "130-高脚杯侧弓箭步",
    videoId: "YDFH_348",
    videoUrl:
      "http://video.zhijianfit.com/sv/1c8f0142-1936133b410/1c8f0142-1936133b410.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3C1BE5249FE34258900889359B12F78C-6-2.jpg",
  },
  {
    name: "131-坐姿推举",
    videoId: "YDFH_353",
    videoUrl:
      "http://video.zhijianfit.com/sv/f0a66ba-1936133b4bb/f0a66ba-1936133b4bb.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F7D09037D9B9442C930FA4AF46F11D6D-6-2.jpg",
  },
  {
    name: "132-站姿推举",
    videoId: "YDFH_354",
    videoUrl:
      "http://video.zhijianfit.com/sv/626a733e-1936133b548/626a733e-1936133b548.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/250AC2D74E3D4B3EBEF1852230413AA1-6-2.jpg",
  },
  {
    name: "133-支撑坐姿推举",
    videoId: "YDFH_355",
    videoUrl:
      "http://video.zhijianfit.com/sv/80b57d9-1936133b76f/80b57d9-1936133b76f.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3E680E9AA57B4B2CBE4BAE9C5E12ABAB-6-2.jpg",
  },
  {
    name: "134-站姿屈臂下推",
    videoId: "YDFH_361",
    videoUrl:
      "http://video.zhijianfit.com/sv/272c0ceb-1936133b7e8/272c0ceb-1936133b7e8.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/EB2C1CC65A5748F4976F50478811AF2F-6-2.jpg",
  },
  {
    name: "135-坐姿屈臂下推",
    videoId: "YDFH_362",
    videoUrl:
      "http://video.zhijianfit.com/sv/b5085fc-1936133b926/b5085fc-1936133b926.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/35CA02629F8941F7BB3679041DFA9F8D-6-2.jpg",
  },
  {
    name: "136-坐姿前推",
    videoId: "YDFH_368",
    videoUrl:
      "http://video.zhijianfit.com/sv/58bab448-1936133ba03/58bab448-1936133ba03.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/02E26CFF86BA4C77B72DC05C0F9D8ECB-6-2.jpg",
  },
  {
    name: "137-站姿前推",
    videoId: "YDFH_369",
    videoUrl:
      "http://video.zhijianfit.com/sv/2c36b5e9-1936133ba42/2c36b5e9-1936133ba42.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/FFE40A308E5D479B964DB27E8D6305AB-6-2.jpg",
  },
  {
    name: "138-支撑坐姿前推",
    videoId: "YDFH_370",
    videoUrl:
      "http://video.zhijianfit.com/sv/6348acb1-1936133bbea/6348acb1-1936133bbea.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/29C83590F5A3465388249A8A01849B58-6-2.jpg",
  },
  {
    name: "139-杠铃卧推",
    videoId: "YDFH_371",
    videoUrl:
      "http://video.zhijianfit.com/sv/2e411294-1936133bc39/2e411294-1936133bc39.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/930D9866241A4FADB90116637669FED8-6-2.jpg",
  },
  {
    name: "140-站姿提拉",
    videoId: "YDFH_377",
    videoUrl:
      "http://video.zhijianfit.com/sv/4eb96477-1936133bd75/4eb96477-1936133bd75.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CCA0971B63384C10B4BECB4DBC47A7D7-6-2.jpg",
  },
  {
    name: "141-坐姿高位下拉",
    videoId: "YDFH_384",
    videoUrl:
      "http://video.zhijianfit.com/sv/2b39f96a-1936133bdf3/2b39f96a-1936133bdf3.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E8F2D578D2664D4B8AD84ABB65919493-6-2.jpg",
  },
  {
    name: "142-站姿高位下拉",
    videoId: "YDFH_385",
    videoUrl:
      "http://video.zhijianfit.com/sv/8717e0d-1936133be85/8717e0d-1936133be85.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/621E0EC465794C918C28EC1F889FE518-6-2.jpg",
  },
  {
    name: "143-坐姿划船",
    videoId: "YDFH_392",
    videoUrl:
      "http://video.zhijianfit.com/sv/51fe547-1936133c0b1/51fe547-1936133c0b1.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9FBAE0AEADBA473C9FD004BE54E8AD23-6-2.jpg",
  },
  {
    name: "144-站姿划船",
    videoId: "YDFH_393",
    videoUrl:
      "http://video.zhijianfit.com/sv/3a472da5-1936133c0f7/3a472da5-1936133c0f7.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/FC63ACD047184213B93537ED2069A578-6-2.jpg",
  },
  {
    name: "145-支撑坐姿划船",
    videoId: "YDFH_394",
    videoUrl:
      "http://video.zhijianfit.com/sv/2dc2b5c8-1936133c22f/2dc2b5c8-1936133c22f.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/1DC15DF7A248465C9E605AD10CCFFEF9-6-2.jpg",
  },
  {
    name: "146-下飞鸟",
    videoId: "YDFH_401",
    videoUrl:
      "http://video.zhijianfit.com/sv/1cd33620-1936133c356/1cd33620-1936133c356.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D204347D294C486CB0BE4B4738D59055-6-2.jpg",
  },
  {
    name: "147-仰卧下飞鸟",
    videoId: "YDFH_402",
    videoUrl:
      "http://video.zhijianfit.com/sv/64a5e027-1936133c3a8/64a5e027-1936133c3a8.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5B5ED4AAB0894032A906388EC325CCB8-6-2.jpg",
  },
  {
    name: "148-站姿下斜飞鸟",
    videoId: "YDFH_403",
    videoUrl:
      "http://video.zhijianfit.com/sv/44404e5d-1936133c559/44404e5d-1936133c559.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C2CB3936EF7F44A39CD860082E80DB42-6-2.jpg",
  },
  {
    name: "149-坐姿下斜飞鸟",
    videoId: "YDFH_404",
    videoUrl:
      "http://video.zhijianfit.com/sv/2fca8d2f-1936133c5e6/2fca8d2f-1936133c5e6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F5000F95E1DB438081BA95C97281C37C-6-2.jpg",
  },
  {
    name: "150-上飞鸟",
    videoId: "YDFH_405",
    videoUrl:
      "http://video.zhijianfit.com/sv/c10cfae-1936133c6c2/c10cfae-1936133c6c2.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/4E9A5974A2044424925799317D7F9C6A-6-2.jpg",
  },
  {
    name: "151-站姿上斜飞鸟",
    videoId: "YDFH_408",
    videoUrl:
      "http://video.zhijianfit.com/sv/3d8be641-1936133c816/3d8be641-1936133c816.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/BDFE33DE4EC7419F95F97B92D05FA4FA-6-2.jpg",
  },
  {
    name: "152-坐姿上斜飞鸟",
    videoId: "YDFH_409",
    videoUrl:
      "http://video.zhijianfit.com/sv/2c5942c-1936133c984/2c5942c-1936133c984.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C4EED37891FE43DAA1C40A1C504E79B8-6-2.jpg",
  },
  {
    name: "153-前飞鸟",
    videoId: "YDFH_410",
    videoUrl:
      "http://video.zhijianfit.com/sv/3ef5c3c8-1936133ca16/3ef5c3c8-1936133ca16.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D9828E8879B543E8BD19833DCC4DFF60-6-2.jpg",
  },
  {
    name: "154-支撑前飞鸟",
    videoId: "YDFH_411",
    videoUrl:
      "http://video.zhijianfit.com/sv/150f0b6a-1936133ca6f/150f0b6a-1936133ca6f.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/B99039AFD7F1485E903BF8953545BDDC-6-2.jpg",
  },
  {
    name: "155-后飞鸟",
    videoId: "YDFH_414",
    videoUrl:
      "http://video.zhijianfit.com/sv/2dbef314-19361338d9b/2dbef314-19361338d9b.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/602A4017997644E7BE6FBFD42BFF1D9D-6-2.jpg",
  },
  {
    name: "156-支撑后飞鸟",
    videoId: "YDFH_415",
    videoUrl:
      "http://video.zhijianfit.com/sv/13b49e6b-1936133cc55/13b49e6b-1936133cc55.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/312DCB5D17684C28B1FBDABF5E3658D9-6-2.jpg",
  },
  {
    name: "157-过顶深蹲",
    videoId: "YDFH_416",
    videoUrl:
      "http://video.zhijianfit.com/sv/547ddefa-1936133cd95/547ddefa-1936133cd95.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/DEC086390AAC4EFE9E6C57ED40D4C90B-6-2.jpg",
  },
  {
    name: "158-无轴侧向旋转",
    videoId: "YDFH_417",
    videoUrl:
      "http://video.zhijianfit.com/sv/47eb1c53-1936133cdcd/47eb1c53-1936133cdcd.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7B81E5AD1B1B4EB58B2152FEF81352E5-6-2.jpg",
  },
  {
    name: "159-单腿跪姿下劈",
    videoId: "YDFH_418",
    videoUrl:
      "http://video.zhijianfit.com/sv/510fddc0-1936133ced2/510fddc0-1936133ced2.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/CEEE5440DEBB41579848D40C8BAD774B-6-2.jpg",
  },
  {
    name: "160-站姿下劈",
    videoId: "YDFH_419",
    videoUrl:
      "http://video.zhijianfit.com/sv/1fae0a28-193613391a9/1fae0a28-193613391a9.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/1E1B7A6EB9F845FFBA0E9D11CF7F06AF-6-2.jpg",
  },
  {
    name: "161-坐姿下劈",
    videoId: "YDFH_420",
    videoUrl:
      "http://video.zhijianfit.com/sv/65aee8e3-1936133d0e4/65aee8e3-1936133d0e4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/4C2A7672E74441DB87B72A713AC3A63A-6-2.jpg",
  },
  {
    name: "162-单腿跪姿上拉",
    videoId: "YDFH_422",
    videoUrl:
      "http://video.zhijianfit.com/sv/1cc9c862-1936133d261/1cc9c862-1936133d261.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/1912A58F723B49FB98FFBF40458A1412-6-2.jpg",
  },
  {
    name: "163-站姿上拉",
    videoId: "YDFH_423",
    videoUrl:
      "http://video.zhijianfit.com/sv/8d85106-1936133d293/8d85106-1936133d293.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/B2996353D8F049669968DA46C847B3E7-6-2.jpg",
  },
  {
    name: "164-坐姿上拉",
    videoId: "YDFH_424",
    videoUrl:
      "http://video.zhijianfit.com/sv/3b1a02b6-1936133d488/3b1a02b6-1936133d488.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7DAAF27D0FB24BE4AB508759CDE45814-6-2.jpg",
  },
  {
    name: "165-单侧上步前推",
    videoId: "YDFH_426",
    videoUrl:
      "http://video.zhijianfit.com/sv/26cfe004-1936133d4cb/26cfe004-1936133d4cb.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/9FE9E3315EDB45808DA41FC90FE16809-6-2.jpg",
  },
  {
    name: "166-单侧退步后拉",
    videoId: "YDFH_430",
    videoUrl:
      "http://video.zhijianfit.com/sv/59a94e7d-1936133d5d4/59a94e7d-1936133d5d4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/92F77909D8DA4B5DBAEAAB77023C3123-6-2.jpg",
  },
  {
    name: "167-体侧前甩",
    videoId: "YDFH_434",
    videoUrl:
      "http://video.zhijianfit.com/sv/5bddf933-1936133d707/5bddf933-1936133d707.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E094052FB38A459DA95790B8719075E3-6-2.jpg",
  },
  {
    name: "168-分腿直臂下劈",
    videoId: "YDFH_435",
    videoUrl:
      "http://video.zhijianfit.com/sv/356e396c-1936133d740/356e396c-1936133d740.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/DBE3F94FA3304FCE94F79EC8922BCCA0-6-2.jpg",
  },
  {
    name: "169-分腿直臂上拉",
    videoId: "YDFH_436",
    videoUrl:
      "http://video.zhijianfit.com/sv/226cc011-1936133d934/226cc011-1936133d934.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5C2AEDDD4F6F4DE1A67375706A1F690F-6-2.jpg",
  },
  {
    name: "170-分腿巴洛夫前推",
    videoId: "YDFH_438",
    videoUrl:
      "http://video.zhijianfit.com/sv/ce6f6d2-1936133d9e5/ce6f6d2-1936133d9e5.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3F8686CBA88E41239F013A253263BA07-6-2.jpg",
  },
  {
    name: "171-巴洛夫前推侧移",
    videoId: "YDFH_439",
    videoUrl:
      "http://video.zhijianfit.com/sv/bbd506d-1936133da86/bbd506d-1936133da86.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/62F6E173BCD14B4EB6A1DF41EC78BF62-6-2.jpg",
  },
  {
    name: "172-站姿巴洛夫前推",
    videoId: "YDFH_440",
    videoUrl:
      "http://video.zhijianfit.com/sv/86e155a-1936133db5d/86e155a-1936133db5d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/09C4DDF5FB3545818F1F3726E90D4B72-6-2.jpg",
  },
  {
    name: "173-分腿巴洛夫上推",
    videoId: "YDFH_442",
    videoUrl:
      "http://video.zhijianfit.com/sv/339c0f5f-1936133dbf1/339c0f5f-1936133dbf1.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E63F67360F354286A555AD6260932AE4-6-2.jpg",
  },
  {
    name: "174-巴洛夫上推侧移",
    videoId: "YDFH_443",
    videoUrl:
      "http://video.zhijianfit.com/sv/1b39549b-1936133dd69/1b39549b-1936133dd69.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/EBCF197DAEC34B628391372818A084D0-6-2.jpg",
  },
  {
    name: "175-站姿巴洛夫上推",
    videoId: "YDFH_444",
    videoUrl:
      "http://video.zhijianfit.com/sv/2fd6ae7a-1936133dde7/2fd6ae7a-1936133dde7.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F0DFD1DD07C845A2A3E991FEAFBAAFD7-6-2.jpg",
  },
  {
    name: "176-平板推",
    videoId: "YDFH_446",
    videoUrl:
      "http://video.zhijianfit.com/sv/501ef9ae-1936133ddf6/501ef9ae-1936133ddf6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/22DE4AB4BA844F2B95EFB9D36BCC5DD7-6-2.jpg",
  },
  {
    name: "177-平板侧拉",
    videoId: "YDFH_450",
    videoUrl:
      "http://video.zhijianfit.com/sv/40c40689-1936133dff1/40c40689-1936133dff1.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/3A5388546C7F4735A3B2BA2326DAB405-6-2.jpg",
  },
  {
    name: "178-平板旋转侧拉",
    videoId: "YDFH_451",
    videoUrl:
      "http://video.zhijianfit.com/sv/2a363f9b-1936133e008/2a363f9b-1936133e008.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/A4D26DE360A94C70894F8E5B4FECD024-6-2.jpg",
  },
  {
    name: "179-平板拉",
    videoId: "YDFH_454",
    videoUrl:
      "http://video.zhijianfit.com/sv/2419b2cb-1936133e0a4/2419b2cb-1936133e0a4.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/BC63B48E2E564F8FA4F14B5A4B395E8F-6-2.jpg",
  },
  {
    name: "180-侧平板拉",
    videoId: "YDFH_456",
    videoUrl:
      "http://video.zhijianfit.com/sv/448d6bce-1936133e158/448d6bce-1936133e158.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/A56BB5BF1CA44CC99E87F966123D7200-6-2.jpg",
  },
  {
    name: "181-鸟狗式",
    videoId: "YDFH_458",
    videoUrl:
      "http://video.zhijianfit.com/sv/1ca39a13-1936133e1a9/1ca39a13-1936133e1a9.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/2C7519225E0C40B5BE468B0E787387BA-6-2.jpg",
  },
  {
    name: "182-死虫式",
    videoId: "YDFH_462",
    videoUrl:
      "http://video.zhijianfit.com/sv/533a045e-1936133e3c2/533a045e-1936133e3c2.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F6522D60AE4D4EF3A7E7DAA3FF155B74-6-2.jpg",
  },
  {
    name: "183-直退死虫式",
    videoId: "YDFH_463",
    videoUrl:
      "http://video.zhijianfit.com/sv/10f533c9-1936133e417/10f533c9-1936133e417.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D815AC16CD734AB0AFCE21485135E223-6-2.jpg",
  },
  {
    name: "184-台阶上步-把手",
    videoId: "YDFH_468",
    videoUrl:
      "http://video.zhijianfit.com/sv/2ac4d3f-1936133e4ac/2ac4d3f-1936133e4ac.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/2381DD55F36342BE837A2995F86D7319-6-2.jpg",
  },
  {
    name: "185-台阶上步-直杆",
    videoId: "YDFH_469",
    videoUrl:
      "http://video.zhijianfit.com/sv/64bd4b5a-1936133e582/64bd4b5a-1936133e582.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/B2472E9627CE4CE29C0D4E21EC86764E-6-2.jpg",
  },
  {
    name: "186-台阶上步-腰带",
    videoId: "YDFH_470",
    videoUrl:
      "http://video.zhijianfit.com/sv/50f878c6-1936133e5a6/50f878c6-1936133e5a6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/1BD6EA28CA61418CB8F935CE3545B71B-6-2.jpg",
  },
  {
    name: "187-双台阶上步-把手",
    videoId: "YDFH_476",
    videoUrl:
      "http://video.zhijianfit.com/sv/1591b04a-1936133e85d/1591b04a-1936133e85d.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/297607A99F0A4F59A1662419F30CDEAD-6-2.jpg",
  },
  {
    name: "188-双台阶上步-直杆",
    videoId: "YDFH_477",
    videoUrl:
      "http://video.zhijianfit.com/sv/49b6a2bc-1936133e8bf/49b6a2bc-1936133e8bf.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/FAE3D85CCC6D47019E9E2ECCD77ACEB2-6-2.jpg",
  },
  {
    name: "189-台阶蹬伸-把手",
    videoId: "YDFH_482",
    videoUrl:
      "http://video.zhijianfit.com/sv/627052b5-1936133e902/627052b5-1936133e902.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/0BBACEE4DA6A4A5F8C816B35F95E79CF-6-2.jpg",
  },
  {
    name: "190-台阶蹬伸-直杆",
    videoId: "YDFH_483",
    videoUrl:
      "http://video.zhijianfit.com/sv/185b4796-1936133ea39/185b4796-1936133ea39.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/2E8A1B3D80004E6F843F2C64C10C6448-6-2.jpg",
  },
  {
    name: "191-高蛙跳",
    videoId: "YDFH_490",
    videoUrl:
      "http://video.zhijianfit.com/sv/418dee87-1936133eaec/418dee87-1936133eaec.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/8DD8CB6027214D799C84AD10E855B90D-6-2.jpg",
  },
  {
    name: "192-单腿蹬跳-直杆",
    videoId: "YDFH_491",
    videoUrl:
      "http://video.zhijianfit.com/sv/5635af15-1936133ecbe/5635af15-1936133ecbe.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/43D797D3131A4A8CB0B06738D8AF165F-6-2.jpg",
  },
  {
    name: "193-单腿蹬跳-把手",
    videoId: "YDFH_492",
    videoUrl:
      "http://video.zhijianfit.com/sv/33464278-1936133ed3e/33464278-1936133ed3e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E82BBC7FB6304E679066587E6B60137D-6-2.jpg",
  },
  {
    name: "194-弓箭步蹲跳-直杆",
    videoId: "YDFH_499",
    videoUrl:
      "http://video.zhijianfit.com/sv/62dc7cd2-1936133ed99/62dc7cd2-1936133ed99.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/25D8D4F61DB04D20AC2DD0FA6496D648-6-2.jpg",
  },
  {
    name: "195-弓箭步蹲跳-把手",
    videoId: "YDFH_500",
    videoUrl:
      "http://video.zhijianfit.com/sv/60b19dcb-1936133ef00/60b19dcb-1936133ef00.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/51E9BDECC235424DB71FD84F7F619AE8-6-2.jpg",
  },
  {
    name: "196-弓箭步蹲跳-腰带",
    videoId: "YDFH_501",
    videoUrl:
      "http://video.zhijianfit.com/sv/1db68185-1936133efed/1db68185-1936133efed.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/72E7DFC714864922BF5E8DA48A76F75B-6-2.jpg",
  },
  {
    name: "197-箱跳-直杆",
    videoId: "YDFH_508",
    videoUrl:
      "http://video.zhijianfit.com/sv/4ecdbe41-1936133f12a/4ecdbe41-1936133f12a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C73618C4671B459F9ECF260C6637B699-6-2.jpg",
  },
  {
    name: "198-箱跳-把手",
    videoId: "YDFH_509",
    videoUrl:
      "http://video.zhijianfit.com/sv/5461b94f-1936133f173/5461b94f-1936133f173.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F17763F07B5142E381570E8AE710AB86-6-2.jpg",
  },
  {
    name: "199-蹬跨步",
    videoId: "YDFH_525",
    videoUrl:
      "http://video.zhijianfit.com/sv/5acae25c-1936133f320/5acae25c-1936133f320.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7B299470712A485F81F35683AE7E2956-6-2.jpg",
  },
  {
    name: "200-反应型蹬跨步",
    videoId: "YDFH_528",
    videoUrl:
      "http://video.zhijianfit.com/sv/2212b77b-1936133f40c/2212b77b-1936133f40c.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/4449CE867AF346E4AFB6F594B98C8D91-6-2.jpg",
  },
  {
    name: "201-支撑蹬伸步",
    videoId: "YDFH_539",
    videoUrl:
      "http://video.zhijianfit.com/sv/62fbca28-1936133f57b/62fbca28-1936133f57b.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/D823EB41733B471EB3D7D9D9E2A63F51-6-2.jpg",
  },
  {
    name: "202-借力挺举",
    videoId: "YDFH_544",
    videoUrl:
      "http://video.zhijianfit.com/sv/ca2beb2-1936133f5b6/ca2beb2-1936133f5b6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/44BFDF2A1A5C4C29B743377D4E0FFF1D-6-2.jpg",
  },
  {
    name: "203-挺举",
    videoId: "YDFH_545",
    videoUrl:
      "http://video.zhijianfit.com/sv/f908f7-1936133f6de/f908f7-1936133f6de.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/720231607B144D55AC458B6F38ED28A1-6-2.jpg",
  },
  {
    name: "204-同侧箭步推举",
    videoId: "YDFH_551",
    videoUrl:
      "http://video.zhijianfit.com/sv/37c9d35d-1936133f7a1/37c9d35d-1936133f7a1.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/13D2CC837DB3485C85EE66D17F3A653A-6-2.jpg",
  },
  {
    name: "205-单侧借力推举",
    videoId: "YDFH_552",
    videoUrl:
      "http://video.zhijianfit.com/sv/6737f97b-1936133f922/6737f97b-1936133f922.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5C4A211D658C4E2B874D12C675598D2F-6-2.jpg",
  },
  {
    name: "206-对侧箭步推举",
    videoId: "YDFH_558",
    videoUrl:
      "http://video.zhijianfit.com/sv/4fe495c3-1936133fa10/4fe495c3-1936133fa10.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/792B9552CC364A63A03D7D364E19ECD1-6-2.jpg",
  },
  {
    name: "207-绞剪推举",
    videoId: "YDFH_559",
    videoUrl:
      "http://video.zhijianfit.com/sv/1a12723f-1936133fa93/1a12723f-1936133fa93.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/595F71D04E7646A994CBF2A15DC1AC2F-6-2.jpg",
  },
  {
    name: "208-高拉",
    videoId: "YDFH_565",
    videoUrl:
      "http://video.zhijianfit.com/sv/ab00d1a-1936133fab8/ab00d1a-1936133fab8.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/6A1915A777A645B9A0061848F87E885A-6-2.jpg",
  },
  {
    name: "209-罗马尼亚硬拉",
    videoId: "YDFH_567",
    videoUrl:
      "http://video.zhijianfit.com/sv/267f71cc-1936133fc11/267f71cc-1936133fc11.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/4FB869CB17AF437BB9D975A75A9C9985-6-2.jpg",
  },
  {
    name: "210-单腿硬拉提膝",
    videoId: "YDFH_572",
    videoUrl:
      "http://video.zhijianfit.com/sv/1f33d366-1936133fd25/1f33d366-1936133fd25.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/346395F804FB4C14BEFC468CE4A627CB-6-2.jpg",
  },
  {
    name: "211-单腿硬拉",
    videoId: "YDFH_573",
    videoUrl:
      "http://video.zhijianfit.com/sv/1db80ebc-1936133c053/1db80ebc-1936133c053.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/7D2A288BCD994B2F8FF909491732AFFF-6-2.jpg",
  },
  {
    name: "212-双侧划船提拉",
    videoId: "YDFH_579",
    videoUrl:
      "http://video.zhijianfit.com/sv/52ab3a9b-1936133fec8/52ab3a9b-1936133fec8.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/66CE01216FD14BCCB731EE5621433DBB-6-2.jpg",
  },
  {
    name: "213-绞剪提拉",
    videoId: "YDFH_580",
    videoUrl:
      "http://video.zhijianfit.com/sv/65479910-1936133ff5e/65479910-1936133ff5e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/B5DF97DF32C84E0A9AFD2A981AE03C5F-6-2.jpg",
  },
  {
    name: "214-双侧上步前推",
    videoId: "YDFH_586",
    videoUrl:
      "http://video.zhijianfit.com/sv/47f80c88-193613400d9/47f80c88-193613400d9.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/316D27159DCC4C978B00B217D1C6E600-6-2.jpg",
  },
  {
    name: "215-侧步平推",
    videoId: "YDFH_587",
    videoUrl:
      "http://video.zhijianfit.com/sv/31b0fa94-19361340112/31b0fa94-19361340112.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E636DD1953FA40F0939E60F13DE2CE43-6-2.jpg",
  },
  {
    name: "216-双侧撤步平拉",
    videoId: "YDFH_593",
    videoUrl:
      "http://video.zhijianfit.com/sv/4791360d-193613401e3/4791360d-193613401e3.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/5999232DD4FD4ADC8DDCB6F44E45B389-6-2.jpg",
  },
  {
    name: "217-侧步平拉",
    videoId: "YDFH_594",
    videoUrl:
      "http://video.zhijianfit.com/sv/60ee6e9a-193613402cd/60ee6e9a-193613402cd.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/2BF94650F5D643BBBCE8C400460EBACB-6-2.jpg",
  },
  {
    name: "218-展拉",
    videoId: "YDFH_600",
    videoUrl:
      "http://video.zhijianfit.com/sv/4fd91315-1936134039a/4fd91315-1936134039a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/4D1126DC3AA84F1A8FAD8B91D66455AC-6-2.jpg",
  },
  {
    name: "219-下砸",
    videoId: "YDFH_606",
    videoUrl:
      "http://video.zhijianfit.com/sv/3fab537-1936134058f/3fab537-1936134058f.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/F2792A6F7148410E8BDCBAD06501CC0D-6-2.jpg",
  },
  {
    name: "220-甩荡",
    videoId: "YDFH_612",
    videoUrl:
      "http://video.zhijianfit.com/sv/8c97524-193613405c6/8c97524-193613405c6.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/6E2B49F959544F33A3C849033DA1A67B-6-2.jpg",
  },
  {
    name: "221-撤步直臂下劈",
    videoId: "YDFH_618",
    videoUrl:
      "http://video.zhijianfit.com/sv/1a782cc3-19361340644/1a782cc3-19361340644.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/76E07AA016B8498B8535EFDCBBD3A295-6-2.jpg",
  },
  {
    name: "222-上步直臂上拉",
    videoId: "YDFH_619",
    videoUrl:
      "http://video.zhijianfit.com/sv/51bac0b7-193613407b2/51bac0b7-193613407b2.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/ED0B909FCB1C4D34982A6F80C580EA8B-6-2.jpg",
  },
  {
    name: "223-平击",
    videoId: "YDFH_620",
    videoUrl:
      "http://video.zhijianfit.com/sv/614075df-1936134082e/614075df-1936134082e.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/000243E566624E64AAA8ED6B9D697B77-6-2.jpg",
  },
  {
    name: "224-反握平击",
    videoId: "YDFH_621",
    videoUrl:
      "http://video.zhijianfit.com/sv/14dff85d-19361340a2a/14dff85d-19361340a2a.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/C42BCBC1A9E64837813337420A4C436F-6-2.jpg",
  },
];

export default data;
