import React from "react";
import { Link } from "react-router-dom";

import style from "./home.module.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={style.root}
        style={{ backgroundImage: "url('./background.png')" }}
      >
        <Link className={style.icon} to="/lib">
          <div className={style.buttonScaler}>
            <div
              className={style.button}
              style={{ backgroundImage: "url('./icon_01lib.png')" }}
            >
              <div className={style.title}>
                <div className={style.name}>
                  <div>改善动作库</div>
                  <div className={style.bar}></div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link className={style.icon} to="/func">
          <div className={style.buttonScaler}>
            <div
              className={style.button}
              style={{ backgroundImage: "url('./icon_02function.png')" }}
            >
              <div className={style.title}>
                <div className={style.name}>
                  <div>三套功能体操</div>
                  <div className={style.bar}></div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link className={style.icon} to="/hiit">
          <div className={style.buttonScaler}>
            <div
              className={style.button}
              style={{ backgroundImage: "url('./icon_03hiit.png')" }}
            >
              <div className={style.title}>
                <div className={style.name}>
                  <div>高强度功能训练</div>
                  <div className={style.bar}></div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
export default Home;
