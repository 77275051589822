import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../home/home";
import Lib from "../01_lib";
import Func from "../02_func";
import Hiit from "../03_hiit";

export default class App extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/lib" element={<Lib />}></Route>
        <Route path="/func" element={<Func />}></Route>
        <Route path="/hiit" element={<Hiit />}></Route>
      </Routes>
    );
  }
}
