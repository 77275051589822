const data = [
  {
    name: "动作模式体操",
    videoId: "YDCF_070",
    videoUrl:
      "http://video.zhijianfit.com/sv/cd6ee21-18165eec3a3/cd6ee21-18165eec3a3.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/941A54925B4C45959FABB4D532B0278D-6-2.jpg",
  },
  {
    name: "灵活性体操",
    videoId: "YDCF_071",
    videoUrl:
      "http://video.zhijianfit.com/sv/23332752-18165eec3a7/23332752-18165eec3a7.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/E545EBA280264B0786CE21DCEE7C02A4-6-2.jpg",
  },
  {
    name: "稳定性体操",
    videoId: "YDCF_072",
    videoUrl:
      "http://video.zhijianfit.com/sv/4446d086-18165eec3d2/4446d086-18165eec3d2.mp4",
    imgUrl:
      "http://video.zhijianfit.com/image/default/AC825ADB25FB4E1E8F06C9AAC6E3BD2C-6-2.jpg",
  },
];

export default data;
